<template>
  <section class="hero">
    <div class="hero-wrapper">
      <div class="hero-left">
        <h1 class="title">Instant Access To 20+ Blockchains At Zero Effort From Your Side
        </h1>
        <h2 class="subtitle">
          Full and archive shared nodes under 99.89% SLA
        </h2>
        <div class="icons">
          <picture>
            <source srcset="../../../assets/blockchain/binance-smart-chain.webp" type="image/webp">
            <img src="../../../assets/blockchain/binance-smart-chain.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/ethereum-gray.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/ethereum-gray.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/solana.webp" type="image/webp">
            <img src="../../../assets/blockchain/solana.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/tron.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/tron.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/ethereum-classic.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/ethereum-classic.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/polkadot.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/polkadot.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/polygon.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/polygon.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/ethereum-pow.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/ethereum-pow.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/avalanche.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/avalanche.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/callisto.webp" type="image/webp">
            <img src="../../../assets/blockchain/callisto.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/neo.webp" type="image/webp">
            <img src="../../../assets/blockchain/neo.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/stellar-black.webp" type="image/webp">
            <img src="../../../assets/blockchain/stellar-black.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/vechain-blue.webp" type="image/webp">
            <img src="../../../assets/blockchain/vechain-blue.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/cosmos.png?as=webp" type="image/webp">
            <img src="../../../assets/blockchain/cosmos.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/algorand.webp" type="image/webp">
            <img src="../../../assets/blockchain/algorand.png" alt="icon" />
          </picture>
          <picture>
            <source srcset="../../../assets/blockchain/monero.webp" type="image/webp">
            <img src="../../../assets/blockchain/monero.png" alt="icon" />
          </picture>
        </div>
      </div>
      <div class="hero-right">
        <form class="form" @submit.prevent="onSubmit">
          <div class="form-title">
            Receive credentials in a couple of clicks
          </div>
          <input
            v-model="email"
            type="text"
            name="Email"
            placeholder="Email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            required
          >
          <button type="submit" class="btn btn--blue">Start for free</button>
          <span class="separator">OR</span>
          <button class="btn btn--gray" @click.prevent="toContactUs">Contact us</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

const email = ref('')

function onSubmit () {
  window.location.href = `./start-for-free.html?Email=${email.value}`
}

function toContactUs () {
  window.location.href = './contact-us.html'
}
</script>

<style lang="scss" scoped>
@import "../styles/utils";

.hero {
  background: #040F70;
}

.hero-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 32px 15px 90px 15px;
  gap: 15px;

  @include centered-max-w(970px);

  @media screen and (max-width: 920px) {
    flex-direction: column;
    gap: 40px;
  }
}

.hero-left {
  max-width: 580px;
  width: 100%;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    max-width: 100%;
  }
}

.title {
  font-weight: 700;
  font-size: 2.1875em;
  line-height: 42px;
  color: #FFF;
  margin-bottom: 30px
}

.subtitle {
  font-weight: 300;
  font-size: 1.8em;
  line-height: 42px;
  color: #FFF;
  margin-bottom: 30px;
}

.icons {
  display: grid;
  grid-template-columns: repeat(8, 57px);
  grid-gap: 15px;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(5, 57px);
    justify-content: center;
  }

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(3, 57px);
    justify-content: center;
  }

  picture {
    display: flex;
    justify-content: center;
    img {
      width: 57px;
      height: 57px;
      object-fit: contain;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  background: #FFF;
  border-radius: 50px;
  max-width: 295px;
  width: 100%;
  padding: 30px;

  @media screen and (max-width: 920px) {
    @include centered-max-w(495px);
  }

  .form-title {
    margin-bottom: 25px;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #393939;
  }

  input {
    background: #FAF9F9;
    border-radius: 35px;
    padding: 15px 22px;
    border: 0;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 22px;
    color: #808080;
    margin-bottom: 10px;
  }

  .btn {
    padding: 15px 22px;
    border-radius: 35px;
    font-weight: 400;
    font-size: 1.25em;
    line-height: 24px;
    text-align: center;
    color: #FFF1F1;
    border: 0;

    &--blue {
      background: #0717A3;
      color: #FFF1F1;
    }

    &--gray {
      background: #EEEEEE;
      color: #393939;
    }
  }

  .separator {
    text-align: center;
    margin: 10px 0;
  }
}
</style>
