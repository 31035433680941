<template>
  <section class="solution" id="solutions">
    <div class="wrapper">
      <h3 class="title">End-to-End Solution For Those Who…</h3>
      <div class="list">
        <div class="list-item">
          <div class="text">🚀 Need a superior rate capacity to seamlessly send millions of requests</div>
          <div class="description">Up to 15,000 requests per minute. No limits applied to the total monthly number of queries</div>
        </div>
        <div class="list-item">
          <div class="text">🌐 Run their own nodes but need a reliable backup during a turbulence</div>
          <div class="description">15 minutes to connect, zero-code to switch back. No vendor lock-in</div>
        </div>
        <div class="list-item">
          <div class="text">🏁 Wanna enter Web3 in a flash, build, experiement, and iterate</div>
          <div class="description">Affordable infrastructure that allows to fully concentrate on your product from day one</div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../styles/utils";

.solution {
 background: #FFF;
}

.wrapper {
  padding: 80px 15px;
  @include centered-max-w(1230px)
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 2.1875em;
  line-height: 42px;
  margin-bottom: 57px;
  color: #393939;
}

.list {
  display: flex;
  gap: 45px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }

  &-item {
    flex: 1;
    padding: 30px;
    background: #FAF9F9;
    border-radius: 30px;
    color: #393939;

    .text {
      margin-bottom: 30px;
      font-weight: 300;
      font-size: 1.5em;
      line-height: 29px;
    }

    .description {
      font-weight: 400;
      font-size: 1.5em;
      line-height: 29px;
    }
  }
}
</style>
