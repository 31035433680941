<template>
  <header class="header">
    <div class="header-wrapper">
      <div class="logo">
        Asternodes
      </div>
      <nav class="navigation">
        <ul class="navigation-list">
          <li class="navigation-list__item">
            <a href="#solutions">Solutions</a>
          </li>
          <li class="navigation-list__item">
            <a href="#chains">Chains</a>
          </li>
          <li class="navigation-list__item">
            <a href="#pricing">Pricing</a>
          </li>
          <li class="navigation-list__item">
            <a href="./docs.html">Docs</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "../styles/utils";

.header {
  background: #040F70;
  color: #FFF;
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 15px;
  gap: 15px;

  @include centered-max-w(1100px);

  @media screen and (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.logo {
  font-size: 2em;
  line-height: 38px;
  font-weight: 400;
}

.navigation {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 770px) {
    justify-content: flex-start;
  }
}

.navigation-list {
  display: flex;
  font-size: 1.5em;
  line-height: 29px;
  font-weight: 400;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  &__item {
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
