<template>
  <main>
    <AppHeader />
    <SectionHero />
    <SectionSolution />
    <SectionTrusted />
    <SectionBenefits />
    <SectionFundamentals />
    <SectionChains />
    <SectionPricing />
    <SectionDiscuss />
    <AppFooter />
  </main>
</template>

<script setup>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import SectionHero from './components/SectionHero.vue'
import SectionSolution from './components/SectionSolution.vue'
import SectionTrusted from './components/SectionTrusted.vue'
import SectionBenefits from './components/SectionBenefits.vue'
import SectionFundamentals from './components/SectionFundamentals.vue'
import SectionChains from './components/SectionChains.vue'
import SectionPricing from './components/SectionPricing.vue'
import SectionDiscuss from './components/SectionDiscuss.vue'
</script>
