<template>
  <section class="trusted">
    <div class="wrapper">
      <h3 class="title">Trusted by</h3>
      <div class="list">
        <div class="list-item">
          <a target="_blank" href="https://ari10.com/">
            <picture>
              <source srcset="../../../assets/ari.webp" type="image/webp">
              <img src="../../../assets/ari.png" alt="ari10">
            </picture>
          </a>
        </div>
        <div class="list-item">
          <a target="_blank" href="https://bitscrunch.com/">
            <picture>
              <source srcset="../../../assets/bitscrunch.webp" type="image/webp">
              <img src="../../../assets/bitscrunch.png" alt="bitscrunch">
            </picture>
          </a>
        </div>
        <div class="list-item">
          <a target="_blank" href="https://onez.io/">
            <picture>
              <source srcset="../../../assets/onez.webp" type="image/webp">
              <img src="../../../assets/onez.png" alt="onez">
            </picture>
          </a>
        </div>
        <div class="list-item">
          <a target="_blank" href="https://quantor.co/">
            <picture>
              <source srcset="../../../assets/quantor.webp" type="image/webp">
              <img src="../../../assets/quantor.png" alt="quantor">
            </picture>
          </a>
        </div>
        <div class="list-item list-item_text">
          <div>
            Your <span>Ethereum node</span> works amazing for <span>debug traces</span> with callTracer! We've tested <span>16 options</span> for a certain tx, your node is the only one that works. <span>Thanks a lot for your job</span>!
          </div>
          <div class="author">
            📢 David from Quantor
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../styles/utils";

.wrapper {
  padding: 60px 15px 80px 15px;
  @include centered-max-w(1230px);
}

.title {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 2.1875em;
  line-height: 42px;
  text-align: center;
}

.list {
  display: flex;
  align-items: center;
  gap: 35px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  &-item {
    color: #393939;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;

    a {
      cursor: pointer;
      text-decoration: none;
    }

    picture {
      max-width: fit-content;
    }

    &_text {
      flex: 1;
      background: #FAF9F9;
      border-radius: 10px;
      padding: 20px 30px;
      position: relative;

      &::after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        clear: both;
        border-top: 20px solid #faf9f9;
        border-left: 20px solid #0000;
        border-right: 20px solid #0000;
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translateY(-50%) rotate(90deg);
      }
      span {
        color: #000;
      }
    }
  }
}

.author {
  color: #040F70;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  margin-top: 8px;
}
</style>
