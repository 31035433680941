<template>
  <section class="fundamentals">
    <div class="wrapper">
      <h3 class="title">We Care About Fundamentals, You Build<br> What Is Important</h3>
      <div class="list">
        <div class="list-item">
          <div class="header">
            <picture>
              <source srcset="../../../assets/3d-icons/developers.png?as=webp" type="image/webp">
              <img src="../../../assets/3d-icons/developers.png" alt="icon" />
            </picture>
            <div class="title">Developers and startups</div>
          </div>
          <div class="description">Test your product hypothesis on multiple blockchains and experiment with new features</div>
        </div>
        <div class="list-item">
          <div class="header">
            <picture>
              <source srcset="../../../assets/3d-icons/wallet2.png?as=webp" type="image/webp">
              <img src="../../../assets/3d-icons/wallet2.png" alt="icon" />
            </picture>
            <div class="title">Exchanges and wallets</div>
          </div>
          <div class="description">Integrate new currencies, receive information on account balances and transactions</div>
        </div>
        <div class="list-item">
          <div class="header">
            <picture>
              <source srcset="../../../assets/3d-icons/nftgame.png?as=webp" type="image/webp">
              <img src="../../../assets/3d-icons/nftgame.png" alt="icon" class="care__item-icon" />
            </picture>
            <div class="title">GameFi and<br>NFT</div>
          </div>
          <div class="description">Verify NFT ownership, authenticity, and collect assets from multiple blockchains</div>
        </div>
        <div class="list-item">
          <div class="header">
            <picture>
              <source srcset="../../../assets/3d-icons/data.png?as=webp" type="image/webp">
              <img src="../../../assets/3d-icons/data.png" alt="icon" class="care__item-icon" />
            </picture>
            <div class="title">Data and analytics</div>
          </div>
          <div class="description">Access accurate historical and real-time blockchain data</div>
        </div>
        <div class="list-item">
          <div class="header">
            <picture>
              <source srcset="../../../assets/3d-icons/defi.png?as=webp" type="image/webp">
              <img src="../../../assets/3d-icons/defi.png" alt="icon" />
            </picture>
            <div class="title">DeFi</div>
          </div>
          <div class="description">Deploy smart contracts and connect your services with a greater number of networks</div>
        </div>
        <div class="list-item">
          <div class="header">
            <picture>
              <source srcset="../../../assets/3d-icons/dapp.png?as=webp" type="image/webp">
              <img src="../../../assets/3d-icons/dapp.png" alt="icon" />
            </picture>
            <div class="title">Other dApps</div>
          </div>
          <div class="description">Concentrate on your product while all crucial components are maintained for you</div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../styles/utils";

.fundamentals {
  background-color: #FFF;
}

.wrapper {
  padding: 70px 15px 50px 15px;
  @include centered-max-w(1060px);
}

.title {
  font-weight: 500;
  font-size: 2.1875em;
  line-height: 42px;
  text-align: center;
  margin-bottom: 90px;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 70px;

  @media screen and (max-width: 870px) {
    grid-template-columns: repeat(2, 3fr);
  }

  @media screen and (max-width: 770px) {
    grid-template-columns: repeat(1, 3fr);
  }
}

.list-item {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    picture {
      display: flex;
      img {
        width: 80px;
        object-fit: contain;
      }
    }

    .title {
      margin-left: 15px;
      margin-bottom: 0;
      text-align: left;
      font-weight: 500;
      font-size: 1.5em;
      line-height: 29px;
      color: #393939;
    }
  }

  .description {
    font-weight: 300;
    font-size: 1.5em;
    line-height: 29px;
    color: #393939;
  }
}
</style>
