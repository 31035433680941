<template>
  <section class="chains" id="chains">
    <div class="wrapper">
      <h3 class="title">Supported Chains</h3>
      <p class="sla">99.89% SLA</p>
      <div class="list">
        <div v-for="c in chains" :key="c.name" class="list-item">
          <div class="icon">
            <picture>
              <img :src="c.icon" :alt="c.name" />
            </picture>
          </div>
          <div class="name">{{ c.name }}</div>
          <div class="info">
            <div class="type-col">
              <div class="type">Type</div>
              <div class="value" v-html="c.type" />
            </div>
            <div class="api-col">
              <div class="type">API</div>
              <div class="value" v-html="c.api" />
            </div>
          </div>
          <div class="actions">
            <a href="./get-access.html">Get access</a>
          </div>
        </div>
      </div>
      <div class="info">
        *Available for the special order. Please <a href="./contact-us.html">contact our support</a> if you'd like to
        access Ethereum archive node.
      </div>
    </div>
  </section>
</template>

<script setup>
const chains = [
  {
    icon: new URL('../../../assets/blockchain/binance-smart-chain.png?width=250', import.meta.url),
    name: 'Binance Smart Chain',
    type: 'Full,<br>Archive',
    api: 'JSON-RPC, WS',
  },
  {
    icon: new URL('../../../assets/blockchain/ethereum.png?width=250', import.meta.url),
    name: 'Ethereum',
    type: 'Full,<br>Archive',
    api: 'JSON-RPC, WS',
  },
  {
    icon: new URL('../../../assets/blockchain/polygon.png?width=250', import.meta.url),
    name: 'Polygon',
    type: 'Full',
    api: 'JSON-RPC, WS',
  },
  {
    icon: new URL('../../../assets/blockchain/polkadot.png?width=250', import.meta.url),
    name: 'Polkadot',
    type: 'Full',
    api: 'JSON-RPC, WS',
  },
  {
    icon: new URL('../../../assets/blockchain/tron.png?width=250', import.meta.url),
    name: 'TRON',
    type: 'Full',
    api: 'REST',
  },
  {
    icon: new URL('../../../assets/blockchain/ethereum-classic.png?width=250', import.meta.url),
    name: 'Ethereum Classic',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/avalanche.png?width=250', import.meta.url),
    name: 'Avalanche',
    type: 'Full, <br>Archive',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/neo.png?width=250', import.meta.url),
    name: 'NEO 3.0',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/callisto.png?width=250', import.meta.url),
    name: 'Callisto',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/stellar-black.png?width=250', import.meta.url),
    name: 'Stellar',
    type: 'Full',
    api: 'REST',
  },
  {
    icon: new URL('../../../assets/blockchain/vechain-blue.png?width=250', import.meta.url),
    name: 'VeChain',
    type: 'Full',
    api: 'REST',
  },
  {
    icon: new URL('../../../assets/blockchain/monero.png?width=250', import.meta.url),
    name: 'Monero',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/algorand.png?width=250', import.meta.url),
    name: 'Algorand',
    type: 'Full',
    api: 'REST',
  },
  {
    icon: new URL('../../../assets/blockchain/cosmos.png?width=250', import.meta.url),
    name: 'Cosmos',
    type: 'Full',
    api: 'REST',
  },
  {
    icon: new URL('../../../assets/blockchain/ethereum-pow.png?width=250', import.meta.url),
    name: 'EthereumPoW',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/Tezos.png?width=250', import.meta.url),
    name: 'Tezos',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/Arbitrum.png?width=250', import.meta.url),
    name: 'Arbitrum',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/XinFin.png?width=250', import.meta.url),
    name: 'XinFin',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/Fantom.png?width=250', import.meta.url),
    name: 'Fantom',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/Optimism.png?width=250', import.meta.url),
    name: 'Optimism',
    type: 'Full',
    api: 'JSON-RPC',
  },
  {
    icon: new URL('../../../assets/blockchain/NEAR.png?width=250', import.meta.url),
    name: 'NEAR',
    type: 'Full',
    api: 'JSON-RPC',
  },
]
</script>

<style lang="scss" scoped>
@import '../styles/utils';

.chains {
  background: #faf9f9;
}

.wrapper {
  padding: 70px 15px 25px 15px;
  @include centered-max-w(1180px);
}

.title {
  font-weight: 500;
  font-size: 2.1875em;
  line-height: 42px;
  text-align: center;
  color: #393939;
  margin-bottom: 15px;
}

.sla {
  font-weight: 400;
  font-size: 2.1875em;
  line-height: 42px;
  color: #393939;
  text-align: center;
  margin: 0 0 70px 0;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 40px;

  @media screen and (max-width: 870px) {
    grid-template-columns: repeat(3, 4fr);
    gap: 30px;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 4fr);
    gap: 15px;
  }

  @media screen and (max-width: 570px) {
    grid-template-columns: repeat(1, 4fr);
    gap: 15px;
  }
}

.list-item {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 50px;
  padding: 15px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    picture {
      display: flex;
      img {
        width: 70px;
        object-fit: contain;
      }
    }
  }

  .name {
    font-weight: 500;
    font-size: 1.3em;
    line-height: 27px;
    text-align: center;
    color: #393939;
    margin-bottom: 15px;
  }

  .info {
    display: flex;
    flex-grow: 1;
    margin-bottom: 15px;

    .type-col {
      flex: 1;
    }

    .api-col {
      flex: 1;
    }
  }

  .type {
    font-weight: 400;
    font-size: 1.25em;
    line-height: 24px;
    text-align: center;
    color: #0717a3;
    margin-bottom: 10px;
  }

  .value {
    font-weight: 300;
    font-size: 1em;
    line-height: 19px;
    text-align: center;
    color: #393939;
  }

  .actions {
    text-align: center;

    a {
      display: inline-block;
      padding: 15px 10px;
      border-radius: 15px;
      background: #faf9f9;
      font-weight: 600;
      font-size: 1em;
      line-height: 21px;
      text-align: center;
      color: #0717a3;
      border: 0;
      text-transform: uppercase;
    }
  }
}

.info {
  font-style: normal;
  font-weight: 300;
  font-size: 0.875em;
  line-height: 17px;
  text-align: left;
  color: #393939;

  a {
    color: #0717a3;
  }
}
</style>
