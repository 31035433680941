<template>
  <section class="pricing" id="pricing">
    <div class="wrapper">
      <h3 class="title">Pricing</h3>
      <div class="pricing-table">
        <table>
          <tbody>
            <tr>
              <th></th>
              <th>Developer<br /><span class="light">for 2 weeks</span></th>
              <th>Growth</th>
              <th>Business 🔥</th>
              <th>Custom</th>
            </tr>
            <tr>
              <td>Price</td>
              <td>Free</td>
              <td>14$</td>
              <td>89$</td>
              <td>Contact us</td>
            </tr>
            <tr>
              <td>Requests per minute</td>
              <td>5 RPM</td>
              <td>150 RPM</td>
              <td>3,000 RPM</td>
              <td>20+ blockchains to build what you want</td>
            </tr>
            <tr class="b-500">
              <td>Maximum number of requests*</td>
              <td>
                <span>
                  <b>7,200</b> <br />requests/day <br /><br />
                  Up to <b>210K</b> requests/mo
                </span>
              </td>
              <td>
                <span>
                  <b>216,000</b> <br />requests/day <br /><br />
                  Up to <b>6.5M</b> requests/mo
                </span>
              </td>
              <td>
                <span>
                  <b>4.32M</b> <br />requests/day <br /><br />
                  Up to <b>130M</b> requests/mo
                </span>
              </td>
              <td>
                <span> Custom plan to pay for what you need </span>
              </td>
            </tr>
            <tr>
              <td>Access to all blockchains</td>
              <td>
                <picture>
                  <source srcset="../../../assets/done.webp" type="image/webp" />
                  <img src="../../../assets/done.png" alt="done" />
                </picture>
              </td>
              <td>
                <picture>
                  <source srcset="../../../assets/done.webp" type="image/webp" />
                  <img src="../../../assets/done.png" alt="done" />
                </picture>
              </td>
              <td>
                <picture>
                  <source srcset="../../../assets/done.webp" type="image/webp" />
                  <img src="../../../assets/done.png" alt="done" />
                </picture>
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>Support</td>
              <td>24h response time</td>
              <td>24h response time</td>
              <td>Live chat with Asternodes’ team<br />Priority support</td>
              <td></td>
            </tr>
            <tr>
              <td>Additional requests</td>
              <td></td>
              <td>1$/day for switching to 200 RPM</td>
              <td>3$/day for switching to 4,000 RPM</td>
              <td></td>
            </tr>
            <tr>
              <td>Archive requests</td>
              <td></td>
              <td></td>
              <td>
                <img src="../../../assets/done.png" alt="done" />
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td><a href="./get-started.html">Start</a></td>
              <td><a href="./get-started.html">Start</a></td>
              <td><a href="./get-started.html">Start</a></td>
              <td><a href="./contact-us.html">Start</a></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="info">
        <span
          >*We do not limit your total number of total daily, weekly, or monthly requests. Leverage full capacity of
          your RPM rate!</span
        >
        <span> **Simple payment with crypto! </span>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '../styles/utils';

.pricing {
  background: #fff;
}

.wrapper {
  padding: 50px 15px;
  @include centered-max-w(1280px);
}

.title {
  font-weight: 500;
  font-size: 2.1875em;
  line-height: 42px;
  color: #393939;
  text-align: center;
  margin-bottom: 70px;
}

.pricing-table {
  text-align: center;
  margin-bottom: 57px;

  table {
    border-collapse: separate;
    border-spacing: 30px 0;
    display: flex;
    overflow-x: auto;
  }

  tbody {
    width: 100%;
    flex-grow: 1;
  }

  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }

  tr {
    &:first-child {
      th,
      td {
        &:not(:first-child) {
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
        }
      }
    }

    &:nth-child(2) {
      td {
        &:not(:first-child) {
          font-weight: 400;
          font-size: 1.5em;
          line-height: 29px;
          text-align: center;
          color: #393939;
        }
      }
    }

    &:last-child {
      th,
      td {
        &:not(:first-child) {
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 30px;
        }
      }
    }
  }

  th {
    padding: 30px 30px;
    font-weight: 500;
    font-size: 1.6em;
    line-height: 24px;
    text-align: center;
    color: #0717a3;

    .light {
      font-weight: 300;
      font-size: 0.875em;
      line-height: 17px;
      text-align: center;
    }

    &:first-child {
      padding: 30px 30px 30px 0;
    }

    &:not(:first-child) {
      background: #faf9f9;
    }
  }

  td {
    padding: 10px 30px;

    &:first-child {
      padding: 10px 30px 10px 0;
      text-align: left;
      font-weight: 300;
      font-size: 1.125em;
      line-height: 22px;
      color: #393939;
    }

    &:not(:first-child) {
      background-color: #faf9f9;
      font-weight: 400;
      font-size: 1em;
      line-height: 17px;
      text-align: center;
      color: #393939;
    }

    a {
      display: inline-block;
      background: #676767;
      border-radius: 40px;
      border: 0;
      padding: 14px 48px;
      font-weight: 500;
      font-size: 1.25em;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
}

.info {
  font-weight: 400;
  font-size: 1em;
  line-height: 19px;
  color: #5c5c5c;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.b-500 {
  b {
    font-weight: 500;
  }
}
</style>
