<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__links">
        <div class="footer__text">Company</div>
        <a target="_blank" href="./docs.html" class="footer__link">Docs</a>
        <a target="_blank" href="./contact-us.html" class="footer__link">Contact us</a>
      </div>
      <div class="footer__links">
        <div class="footer__text">Legal</div>
        <a target="_blank" href="./privacy-policy.html" class="footer__link">Privacy Policy</a>
        <a target="_blank" href="./terms-of-service.html" class="footer__link">Terms of Service</a>
      </div>
      <div class="footer__links">
        <div class="footer__text">Connect with us!</div>
        <div class="footer__icons">
          <a target="_blank" href="https://t.me/asternodes_team" class="footer__link">
            <picture>
              <source srcset="../../../assets/social-media/telegram.png?as=webp" type="image/webp">
              <img src="../../../assets/social-media/telegram.png" alt="icon" class="footer__link-icon" />
            </picture>
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/asternodes" class="footer__link">
            <picture>
              <source srcset="../../../assets/social-media/linkedin.png?as=webp" type="image/webp">
              <img src="../../../assets/social-media/linkedin.png" alt="icon" class="footer__link-icon" />
            </picture>
          </a>
          <a target="_blank" href="https://twitter.com/asternodes" class="footer__link">
            <picture>
              <source srcset="../../../assets/social-media/twitter.png?as=webp" type="image/webp">
              <img src="../../../assets/social-media/twitter.png" alt="icon" class="footer__link-icon" />
            </picture>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  padding: 38px 0;
  background-color: #0717A3;

  &__content {
    width: 65%;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }
  &__text {
    color: #D1D1D1;
    font-size: 22px;
    line-height: 1.55;
    font-weight: 400;
    margin-bottom: 12px;
  }
  &__links {
    display: flex;
    flex-direction: column;
  }
  &__link {
    margin-bottom: 12px;
    color: #FAFAFA;
    font-size: 20px;
    line-height: 1.55;
    font-weight: 400;
    text-decoration: none;

    &-icon {
      width: 26px;
      height: 26px;
      object-fit: contain;
    }
  }
  &__icons {
    display: flex;
    align-items: center;
    .footer__link:not(:last-child) {
      margin-right: 10px;
    }
  }

  @media (max-width: 640px) {

    &__links {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
    &__text {
      font-size: 16px;
    }
    &__link {
      font-size: 16px;
      &-icon {
        width: 23px;
        height: 23px;
      }
    }
  }
  @media (max-width: 450px) {
    font-size: 12px;
    padding: 20px 0;

    &__content {
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      justify-content: center;
    }
    &__links {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    &__link,
    &__text {
      margin-bottom: 6px;
    }
    &__link {
      &-icon {
        width: 19px;
        height: 19px;
      }
    }
  }
}
</style>
