<template>
  <section class="benefits">
    <div class="wrapper">
      <h3 class="title">Unique Set Of Benefits</h3>
      <div class="benefits-table">
        <table>
          <tbody>
            <tr>
              <th></th>
              <th>Mainstream nodes providers</th>
              <th>
                <div class="logo-wrapper">
                  <span>Asternodes</span>
                  <picture>
                    <source srcset="../../../assets/logo.webp" type="image/webp">
                    <img src="../../../assets/logo.png" alt="logo">
                  </picture>
                </div>
              </th>
            </tr>
            <tr>
              <td><span class="with-dot">Trace and debug modules on each plan</span></td>
              <td>
                <picture class="close-icon">
                  <source srcset="../../../assets/close.webp" type="image/webp">
                  <img src="../../../assets/close.png" alt="fail" />
                </picture>
              </td>
              <td>
                <picture class="done-icon">
                  <source srcset="../../../assets/done.webp" type="image/webp">
                  <img src="../../../assets/done.png" alt="done"/>
                </picture>
              </td>
            </tr>
            <tr>
              <td><span class="with-dot">API uptime</span></td>
              <td>>99%</td>
              <td>>99.89%</td>
            </tr>
            <tr>
              <td><span class="with-dot">Archive nodes</span></td>
              <td>
                <picture class="close-icon">
                  <source srcset="../../../assets/close.webp" type="image/webp">
                  <img src="../../../assets/close.png" alt="fail" />
                </picture>
              </td>
              <td>
                <picture class="done-icon">
                  <source srcset="../../../assets/done.webp" type="image/webp">
                  <img src="../../../assets/done.png" alt="done"/>
                </picture>
              </td>
            </tr>
            <tr>
              <td><span class="with-dot">JSON-RPC, REST, and WebSocket API interfaces</span></td>
              <td>
                <picture class="close-icon">
                  <source srcset="../../../assets/close.webp" type="image/webp">
                  <img src="../../../assets/close.png" alt="fail" />
                </picture>
              </td>
              <td>
                <picture class="done-icon">
                  <source srcset="../../../assets/done.webp" type="image/webp">
                  <img src="../../../assets/done.png" alt="done"/>
                </picture>
              </td>
            </tr>
            <tr>
              <td><span class="with-dot">Unlimited requests</span></td>
              <td>
                <picture class="close-icon">
                  <source srcset="../../../assets/close.webp" type="image/webp">
                  <img src="../../../assets/close.png" alt="fail" />
                </picture>
              </td>
              <td>
                <picture class="done-icon">
                  <source srcset="../../../assets/done.webp" type="image/webp">
                  <img src="../../../assets/done.png" alt="done"/>
                </picture>
              </td>
            </tr>
            <tr>
              <td><span class="with-dot">Up to 15K RPM or more</span></td>
              <td>
                <picture class="close-icon">
                  <source srcset="../../../assets/close.webp" type="image/webp">
                  <img src="../../../assets/close.png" alt="fail" />
                </picture>
              </td>
              <td>
                <picture class="done-icon">
                  <source srcset="../../../assets/done.webp" type="image/webp">
                  <img src="../../../assets/done.png" alt="done"/>
                </picture>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../styles/utils";

.benefits {
  background: #FAF9F9;
}

.wrapper {
  padding: 60px 15px 80px 15px;
  @include centered-max-w(960px);
}

.title {
  margin-bottom: 60px;
  font-weight: 500;
  font-size: 2.1875em;
  line-height: 42px;
  text-align: center;
}

.benefits-table {
  text-align: center;
  font-weight: 400;
  font-size: 1.75em;
  overflow-x: auto;

  table {
    width: 100%;
    border-spacing: 0 22px;
    overflow-x: auto;

    th {
      font-weight: 400;
      padding: 0 8px;
    }

    td {
      &:first-child {
        text-align: left;
        font-weight: 300;
      }

      &:nth-child(2) {
        font-weight: 300;
      }

      &:nth-child(3) {
        color: #0717a3;
      }

      padding: 10px 25px;
      vertical-align: middle;
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 12px;
      line-height: 34px;
      color: #0717A3;
    }

    img {
      width: 52px;
      height: 52px;
    }
  }

  .done-icon img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  .close-icon img {
    height: 29px;
    width: 29px;
    object-fit: contain;
  }
}

.with-dot {
  display: list-item;
}
</style>
