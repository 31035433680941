<template>
  <div class="footer">
    <div class="wrapper">
      <div class="text">Need more RPM or didn’t find a<br>blockchain that you were looking for?</div>
      <div class="action">
        <a href="./contact-us.html">Let’s discuss it!</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../styles/utils";

.footer {
  background: #FAF9F9;
}

.wrapper {
  padding: 90px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  @include centered-max-w(1140px);

  @media screen and (max-width: 870px) {
    flex-direction: column;
  }
}

.text {
  font-weight: 500;
  font-size: 1.75em;
  line-height: 34px;
  text-align: center;
  color: #0717A3;
}

.action {
  a {
    display: inline-block;
    background: #0717A3;
    border-radius: 40px;
    border: 0;
    font-weight: 500;
    font-size: 1.875em;
    line-height: 36px;
    text-align: center;
    color: #FCFCFF;
    padding: 20px 70px;
  }
}
</style>
